import React, { useState, useEffect, useCallback } from 'react';

import { createNamedStyled } from '../../stitches.config';

import { useApi } from '../../context/Api';

import Types from '../../modules/types';

const styled = createNamedStyled('PaymentRequestButton');

const ApplePay = styled.named('ApplePay')('div', {
  display: 'inline-block',
  '-webkit-appearance': '-apple-pay-button',
  '-apple-pay-button-type': 'checkout', /* Use any supported button type. */
  '-apple-pay-button-style': 'white-outline',
  borderRadius: '$m',
  borderColor: '$shade50',
  opacity: 0.7,
  width: '100%',
  height: '74px',
  cursor: 'pointer',
});

export default function PaymentRequestButton({ order, store }) {
  const [isSupported, setIsSupported] = useState(false);
  const { charged } = order.cost;
  const {
    country,
    currency,
    checkoutComChannel,
    getCheckoutComVersionBasedOnStoreId,
  } = Types.getSystemCountry(store.systemCountry);
  const api = useApi();
  useEffect(
    () => (async () => {
      if (window.ApplePaySession) {
        try {
          const result = await window.ApplePaySession.canMakePayments();
          setIsSupported(!!result);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      }
    })(),
    [],
  );
  const onClick = useCallback(
    async () => {
      try {
        // const r = new PaymentRequest([], {}, {});
        const paymentRequest = new PaymentRequest(
          [
            {
              supportedMethods: 'https://apple.com/apple-pay',
              data: {
                version: 3,
                merchantIdentifier: process.env[(
                  `REACT_APP_APPLE_PAY_MERCHANT_ID_${
                    checkoutComChannel.toUpperCase()
                  }`
                )],
                merchantCapabilities: [
                  'supports3DS',
                ],
                supportedNetworks: [
                  'amex',
                  'discover',
                  'masterCard',
                  'visa',
                ],
                countryCode: country.toUpperCase(),
              },
            },
          ],
          {
            total: {
              label: 'Total',
              amount: {
                currency,
                value: charged.toFixed(2),
              },
            },
            displayItems: [],
            // displayItems: order.items.map(({ name, quantity, price }) => ({
            //   label: `${quantity} x ${name}`,
            //   amount: {
            //     currency,
            //     value: (price * quantity).toFixed(2),
            //   },
            // })),
            shippingOptions: [],
          },
          {
            // requestShipping: true,
            // shippingType: 'shipping',
          },
        );
        paymentRequest.onmerchantvalidation = (event) => {
          const validationRequest = api.post('apple-pay/validate-session', {
            validationURL: event.validationURL,
            domainName: window.location.hostname,
            checkoutComVersion: getCheckoutComVersionBasedOnStoreId(store._id),
            checkoutComChannel,
          });
          event.complete(validationRequest);
        };
        paymentRequest.onshippingaddresschange = (event) => {
          event.updateWith({});
        };
        const paymentResponse = await paymentRequest.show();
        const submitResponse = await api.post('orders/submit', {
          paymentCardProviderData: {
            applePayData: paymentResponse.details.token.paymentData,
          },
        }, {
          checkout: order._id,
        });
        if (/^INVALID/.test(submitResponse.status || '')) {
          await paymentResponse.complete('fail');
          throw new Error(order.message || 'Submit Failed');
        }
        await paymentResponse.complete('success');
        if (submitResponse?.payment?.paytabsPaymentPageUrl) {
          window.location.href = order.payment.paytabsPaymentPageUrl;
        } else if (submitResponse?.payment?.checkoutRedirectUrl) {
          window.location.href = order.payment.checkoutRedirectUrl;
        } else {
          window.location.reload();
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        window.location.reload();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order, store],
  );
  return (
    isSupported
    ? (
        <ApplePay
          data-purpose="apple-pay"
          onClick={onClick}
        />
      )
    : null
  );
}
