import React, { useMemo } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import { useCart } from '../../../context/Cart';

import { useTheme } from '../../../theme';
import { createNamedStyled } from '../../../stitches.config';

const styled = createNamedStyled('Stripe');

// TODO Maybe move down to the Payment component
// const stripePromise = loadStripe(
//   process.env.REACT_APP_STRIPE_PUBLIC_KEY
// );

let stripePromise = null;

const Wrapper = styled.named('Wrapper')('div', {
  filter: 'grayscale(1)',
});

function PaymentElementComponent() {
  const { stripeCardRef } = useCart();
  const stripe = useStripe();
  const elements = useElements();

  if (stripe && elements) {
    stripeCardRef.current.stripe = stripe;
    stripeCardRef.current.elements = elements;
  }

  const paymentElementOptions = {
    layout: 'tabs',
    fields: {
      billingDetails: {
        address: {
          country: 'never',
          postalCode: 'never',
        },
      },
    },
  };

  return (
    <Wrapper>
      <PaymentElement
        id="payment-element"
        options={paymentElementOptions}
      />
    </Wrapper>
  );
}

function Payment() {
  const { stripePaymentIntent } = useCart();
  const theme = useTheme();

  const appearance = {
    theme: 'stripe',
    variables: {
      colorText: theme.colors.text,

      fontFamily: theme.fonts.label,
      fontSizeBase: '16px',
      fontSizeXl: '16px',
      fontSizeLg: '16px',
      fontSizeSm: '16px',
      fontSizeXs: '16px',
      fontSize2Xs: '16px',
      fontSize3Xs: '16px',
      boxShadow: 'none',

      // borderRadius: theme.radii.s,
    },
    rules: {
      '.Label': {
        // display: 'none',
        color: 'transparent',
        fontsize: 0,
      },
      '.Input': {
        boxShadow: 'none',
        letterSpacing: theme.letterSpacings.label,
        border: 'none',
        borderRadius: 'none',
        borderBottom: `1px solid ${theme.colors.border}`,
      },
      '.Input:focus': {
        boxShadow: 'none',
        outline: 'none',
        borderColor: theme.colors.borderActive,
      },
      '.Input:placeholder': {},
    },
  };
  const stripeOptions = {
    clientSecret: stripePaymentIntent?.paymentIdClient,
    appearance,
  };
  // TODO Maybe move down to the Payment component
  const stripePromiseLocal = useMemo(
    () => {
      stripePromise = stripePromise || loadStripe(
        process.env.REACT_APP_STRIPE_PUBLIC_KEY
      );
      return stripePromise;
    },
    [],
  );
  return (
    <Wrapper>
      <Elements options={stripeOptions} stripe={stripePromiseLocal}>
        <PaymentElementComponent />
      </Elements>
    </Wrapper>
  );
}

export default Payment;
