import React, { useContext } from 'react';
import isObject from 'lodash/isObject';
import getKey from 'lodash/get';

import { createNamedStyled } from '../../../stitches.config';

// import Types from '../../../modules/types';

import CartContext from '../../../context/Cart';
import { useStore } from '../../../context/Store';
import { useDictionary } from '../../../context/Language';

import { Title, Paragraph, Label, Link } from '../Text';
import PriceWithCurrency from '../../../helpers/PriceWithCurrency';
import LinkWrapper from '../../Patterns/Link';
import Number from '../../Patterns/Number';

const styled = createNamedStyled('CartItems');

const Wrapper = styled.named('Wrapper')('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$s',
  width: '100%',
});

const ItemWrapper = styled.named('ItemWrapper')('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 'calc($xs / 2)',
  width: '100%',

  transition: 'opacity $m',
  variants: {
    disabled: {
      true: {
        opacity: 0.3,
      },
    },
  },
});

const Item = styled.named('Item')('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$xs',
});

const ImageWrapper = styled.named('ImageWrapper')('div', {
  position: 'relative',
  flex: '0 0 auto',
  width: '100%',
  maxWidth: '$space$l',
  borderRadius: '$m',
  overflow: 'hidden',
  aspectRatio: '1 / 1',

  '&:after': {
    content: '',
    position: 'absolute',
    inset: 0,
    background: '$cover',
  },
});

const Image = styled.named('Image')('img', {
  position: 'relative',
  width: '100%',
  height: '100%',
  objectFit: '$custom$objectFit',
});

const Content = styled.named('Content')('div', {
  flex: '1 1 auto',
  display: 'flex',
  flexDirection: 'column',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  alignItems: 'flex-start',

  '& > *': {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
});

const Actions = styled.named('Actions')('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  flex: '0 0 auto',
  gap: 'calc($xs / 4)',
  // paddingInlineStart: '$s',
});

const Controls = styled.named('Controls')('div', {
  display: 'flex',
  alignItems: 'center',
  flex: '0 0 auto',
  gap: '$xs',
});

const IconWrapper = styled.named('Icon')('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: '$buttonBackground',
  width: 20,
  height: 20,
  borderRadius: 'calc($s * 1.25)',
  cursor: 'pointer',

  transition: 'transform $m $ease',
  '&:hover': { transform: 'scale(1.1)' },

  variants: {
    disabled: {
      true: {
        pointerEvents: 'none',
        opacity: 0.3,
      },
    },
  },
});

const Icon = ({ onClick, children, isAvailable, ...props }) => (isAvailable ? (
  <IconWrapper onClick={onClick} {...props}>
    <Paragraph css={{ color: '$buttonForeground' }}>
      {children}
    </Paragraph>
  </IconWrapper>
) : <></>);

const Items = ({ items: receivedItems, isInteractive = true }) => {
  const { cartClear } = useDictionary();

  const {
    getProductsUrl: getStoreProductsUrl,
  } = useStore();

  const cart = useContext(CartContext);

  const cartContextItems = cart?.items?.map((item) => ({
    sku: item.sku,
    quantity: item.quantity,
    priceTotal: item.priceTotal,
    name: item.meta.name,
    variation: item.meta.variation,
    image: item.meta.image,
    productSlug: item.meta.productSlug,
    variationSlug: item.meta.variationSlug,
  }));

  const items = receivedItems || cartContextItems;

  return (
    <Wrapper>
      {items.map(({
        sku,
        quantity,
        price,
        priceTotal,
        name,
        variation,
        image,
        productSlug,
        variationSlug,
        additionalInfo,
      }) => {
        const link = `${getStoreProductsUrl(
          productSlug,
          variationSlug,
        )}`;

        const stock = cart?.stock
          ? getKey(cart.stock, sku, 0)
          : Infinity;
        const stockLeftToAdd = stock - quantity;
        return (
          <ItemWrapper
            key={sku}
            disabled={stock === -1 ? false : quantity > stock}
            css={{ ...!isInteractive && { pointerEvents: 'none' } }}
          >
            <Item>
              <ImageWrapper>
                <Image src={image} />
              </ImageWrapper>
              <Content>
                <Title
                  size="xs"
                  as={LinkWrapper}
                  to={link}
                  onClick={() => cart?.toggleShowCart(false)}
                >
                  {name}
                </Title>
                <Paragraph css={{ fontSize: '75%', opacity: 0.75 }}>
                  {variation}
                </Paragraph>
              </Content>
              <Actions>
                <Controls>
                  <Icon
                    isAvailable={isInteractive}
                    onClick={() => cart?.removeFromCart(sku)}
                  >
                    -
                  </Icon>
                  <Label css={{ ...!isInteractive && { fontSize: '70%' } }}>
                    <Number
                      number={
                        !isInteractive ? (
                          <>
                            <PriceWithCurrency value={price} product />
                            {' x '}
                            {quantity}
                          </>
                        ) : (
                          quantity
                        )
                      }
                      quick
                    />
                  </Label>
                  <Icon
                    isAvailable={isInteractive}
                    disabled={stockLeftToAdd < 1}
                    onClick={() => cart?.addToCart(sku)}
                  >
                    +
                  </Icon>
                </Controls>
                <Label>
                  <Number
                    key={priceTotal}
                    number={(
                      <PriceWithCurrency value={priceTotal} product />
                    )}
                  />
                </Label>
              </Actions>
            </Item>
            {!!additionalInfo && (
              isObject(additionalInfo) ? (
                additionalInfo
              ) : (
                <Paragraph css={{ fontSize: '90%' }}>
                  {additionalInfo}
                </Paragraph>
              )
            )}
          </ItemWrapper>
        );
      })}
      {!!isInteractive && (
        <Link
          onClick={() => { cart?.clearCart(); cart?.toggleShowCart(false); }}
          css={{ margin: 'auto' }}
        >
          {cartClear}
        </Link>
      )}
    </Wrapper>
  );
};

export default Items;
