import React, { useEffect, useState } from 'react';
import useDimensions from 'react-use-dimensions';
import getKey from 'lodash/get';

import { createNamedStyled } from '../../../stitches.config';
import { useTheme, useColorOverrides } from '../../../theme';

import { useStore } from '../../../context/Store';

import Logo from '../Logo';
import SocialIcons from '../SocialIcons';

import AutoLayout from '../../Patterns/AutoLayout';

import Announcement from './Announcement';
import Cart from '../Cart/Trigger';

import Items from './Items';
import MobileNavigation from './Mobile';
import Switches from './Switches';

const styled = createNamedStyled('Navigation');

const Wrapper = styled.named('Wrapper')('div', {
  position: 'relative',
  transition: 'box-shadow $m, background $m',
  zIndex: 2,
  paddingBottom: '$space$s',
  '@desktop+': { padding: '$space$xs 0 $space$s' },

  background: '$backgroundHeader',
  borderRadius: '0 0 $l $l',

  // '@mobile': { overflow: 'hidden' },

  variants: {
    isSticky: {
      true: {
        position: 'sticky',
        top: -1,
        zIndex: 2,
      },
    },

    isStuck: {
      true: {
        boxShadow: '0 0 30px $colors$shadow',
        background: '$backgroundHeaderSticky',
      },
    },

    isAway: {
      true: {
        position: 'sticky',
        transition: 'transform $s, border $m, box-shadow $m',
        zIndex: 2,
        background: '$backgroundHeaderSticky',
        boxShadow: '0 0 30px $colors$shadow',
      },
    },

    isScrollingUp: {
      true: {
        transform: 'translateY(100%)',
        transition: 'transform $l $ease, border $m, box-shadow $m',
        background: '$backgroundHeaderSticky',
      },
      detached: {
        transition: 'border $m, box-shadow $m',
      },
    },

    isDynamic: {
      true: {
        // '&:before': {
        //   content: '',
        //   position: 'absolute',
        //   inset: 0,
        //   width: '100%',
        //   height: '100%',
        //   transform: 'translateY(calc(-100% + 0.33px))',
        //   background: '$backgroundHeaderSticky',
        //   zIndex: -1,
        // },
      },
    },

    isAnnouncementHidden: {
      true: {
        top: '16px !important',
      },
    },
  },
});

const Sitewidth = styled.named('SiteWidth')('div', {
  width: '100%',
  maxWidth: '$siteWidth',

  margin: 'auto',

  display: 'flex',
  flexWrap: 'nowrap',
  gap: 'calc($s * 1.5)',
  alignItems: 'center',
  justifyContent: 'space-between',

  padding: '$s $s',
  '@desktop+': { padding: '$s $m' },
});

export const Divider = styled.named('Divider')('div', {
  width: 1,
  height: 12,
  background: '$text',
  opacity: 0.5,
});

const AnnouncementWrapper = styled.named('AnnouncementWrapper')('div', {
  position: 'relative',
  width: '100%',
  zIndex: 1,

  minHeight: '$space$s',

  variants: {
    isHidden: {
      true: {
        display: 'none',
      },
    },
  },
});

const Navigation = ({ hideCart }) => {
  const { navigation } = useTheme();
  const colorOverrides = useColorOverrides('header');
  const colorOverridesSticky = useColorOverrides('headerSticky');

  const [scrollStatus, setScrollStatus] = useState({
    direction: null,
    position: 0,
  });

  useEffect(() => {
    window.addEventListener('scroll', handleScrollDocument);

    return () => window.removeEventListener('scroll', handleScrollDocument);
  }, []);

  function handleScrollDocument() {
    setScrollStatus((prev) => ({
        direction:
          document.body.getBoundingClientRect().top > prev.position
            ? 'up'
            : 'down',
        position: document.body.getBoundingClientRect().top,
      })
    );
  }

  const [measureRef, { height }] = useDimensions();
  const [announcementRef, { height: announcementHeight }] = useDimensions();

  const realHeight = height;
  const fullHeight = height + announcementHeight;

  const isAway = navigation.position === 'dynamic'
    ? scrollStatus.direction === 'down'
      ? scrollStatus.position < -fullHeight
      : scrollStatus.position < -announcementHeight
    : false;

  const isScrollingUp = navigation.position === 'dynamic'
  ? scrollStatus.direction === 'up'
    ? scrollStatus.position < -fullHeight
      ? true
      : scrollStatus.position < -announcementHeight
        ? 'detached'
        : false
    : false : false;

  const isStuck = scrollStatus.position < -announcementHeight;

  const { data: store } = useStore();
  const items = getKey(store, 'navigationItems', []);
  const actions = getKey(store, 'navigationActions', []);

  if (!store) { return null; }

  return (
    <>
      <AnnouncementWrapper
        ref={announcementRef}
        isHidden={
          store?.announcement?.length === 0
          || !store.shopLayout?.announcements.hidden
        }
      >
        {store?.announcement && !store.shopLayout?.announcements.hidden
          ? <Announcement text={store.announcement} />
          : <div />}
      </AnnouncementWrapper>
      <Wrapper
        ref={measureRef}
        isSticky={navigation.position === 'fixed'}
        isDynamic={navigation.position === 'dynamic'}
        isStuck={navigation.position === 'fixed' && isStuck}
        isAway={isAway}
        isScrollingUp={isScrollingUp}
        css={{
          ...isAway && { top: isScrollingUp === 'detached' ? 0 : -realHeight },
        }}
        className={`
          ${colorOverrides}
          ${(isStuck || isAway || isScrollingUp) && colorOverridesSticky}
        `}
        isAnnouncementHidden={
          store?.announcement?.length === 0
          || !store.shopLayout?.announcements.hidden
        }
      >
        <Sitewidth>
          <Logo />
          {navigation.mobileShow === 'always' ? (
            <MobileNavigation
              items={items}
              actions={actions}
              hideCart={hideCart}
            />
          ) : (
            <AutoLayout
              gap="$s"
              align="center"
              justify="flex-start"
              swapWith={(
                <MobileNavigation
                  items={items}
                  actions={actions}
                  hideCart={hideCart}
                />
              )}
              justifySwap="flex-end"
            >
              <Items items={items} />
              <AutoLayout
                gap="$s"
                align="center"
                justify="flex-end"
                hide
              >
                <SocialIcons withDivider />
              </AutoLayout>
              <Switches />
              {!!actions.length && (
                <>
                  <Divider />
                  <Items items={actions} actions />
                </>
              )}
              {
                hideCart
                ? null
                : (
                    <>
                      <Divider />
                      <Cart />
                    </>
                  )
              }
            </AutoLayout>
          )}
        </Sitewidth>
      </Wrapper>

      <div id="mobile-navigation-portal" />
    </>
  );
};

export default Navigation;
