import faviconProductionSrc from '../assets/icons-env/favicon.png';
import faviconDevelopmentSrc from '../assets/icons-env/favicon-development.png';
import faviconStagingSrc from '../assets/icons-env/favicon-staging.png';

export default (
    process.env.REACT_APP_DXTOOLS_ENV === 'development'
  ? faviconDevelopmentSrc
  : process.env.REACT_APP_DXTOOLS_ENV === 'staging'
  ? faviconStagingSrc
  : faviconProductionSrc
);
