import React, { useRef, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import Helmet from 'react-helmet';

import { createNamedStyled, globalCss } from '../../stitches.config';
import StoreThemeProvider from '../../theme';

import GlobalDirection from '../../helpers/GlobalDirection';
// import GlobalBackgroundColor from '../../helpers/GlobalBackgroundColor';

import { useLocation } from '../../context/Location';
import {
  Provider as StoreProvider,
  useStore,
  useStorePage,
} from '../../context/Store';
import { Provider as AnalyticsProvider } from '../../context/Analytics';
import { Provider as CartProvider } from '../../context/Cart';
import { Provider as ProductProvider } from '../../context/Product';

import {
  StoreBoundLanguageProvider,
  useLanguage,
} from '../../context/Language';

import StorePage from './Page';
import StoreProducts from './Products';
import StoreProduct from './Product';

import StoreNavigation from '../../components/Elements/Navigation';
import Footer from '../../components/Elements/Footer';

import Consent from '../../components/Elements/Consent';

import Cart from '../../components/Elements/Cart';
import FloatingCartButton from '../../components/Elements/Cart/Floating';
import CookieConsent from '../../components/Elements/CookieConsent';
import Chat from '../../components/Elements/Chat';

import Types from '../../modules/types';

// import faviconSrc from '../../helpers/faviconSrc';

const styled = createNamedStyled('Store');

const Layout = styled.named('Layout')('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const GlobalStyles = globalCss({
  body: { backgroundColor: '$backgroundWebsite' },
});

function StoreRedirect({
  language,
  isCustomDomain,
  storeSlug,
  productOrPageSlug,
  variationSlug,
  search,
}) {
  return (
    <Redirect
      to={`${
        isCustomDomain ? '' : `/${storeSlug}`
      }/${
        language ? `${language}/` : ''
      }${
        Types.SHOP_PRODUCTS_ARCHIVE_PATH
      }${
        productOrPageSlug ? `/${productOrPageSlug}` : ''
      }${
        variationSlug ? `/${variationSlug}` : ''
      }${
        search
      }`}
    />
  );
}

function Store({ data, language, children, config, posOrderId }) {
  if (!data) {
    return null;
  }

  const layoutConfig = (config ? config.layout : data.shopLayout) || {};

  const hideCart = layoutConfig.cart?.hidden === true;

  return (
    <StoreThemeProvider data={data} language={language}>
      <Helmet>
        <title>{data.name ? data.name : 'Packman'}</title>
        <link
          id="favicon"
          rel="icon"
          href={data?.favicon?.src || ''}
        />
        <link
          rel="apple-touch-icon"
          href={data?.metaImagePwa?.src || data?.posOutletAppIcon?.src || ''}
        />
        <meta
          name="theme-color"
          content={posOrderId
            ? data?.posBackgroundColor || '#fff'
            : data?.metaColorPwa || null}
        />
        <meta
          name="apple-mobile-web-app-title"
          content={data?.metaTitle || data.name}
        />
        {
          data?.favicon?.src || data?.metaImage?.src
          ? (
            <meta
              property="og:image"
              content={data?.metaImage?.src || data?.favicon?.src}
            />
            )
          : null
        }
        {
          data?.metaKeywordsString
          ? <meta name="keywords" content={data?.metaKeywordsString} />
          : null
        }
        {
          data?.metaDescription
          ? <meta name="description" content={data?.metaDescription} />
          : null
        }
        {
          data?.name || data?.metaTitle
          ? <meta property="og:title" content={data?.metaTitle || data.name} />
          : null
        }
        <link
          rel="stylesheet"
          href="https://unicons.iconscout.com/release/v4.0.0/css/line.css"
        />
      </Helmet>

      <GlobalDirection />
      {/* <GlobalBackgroundColor /> */}

      <GlobalStyles />

      <div id="top" />

      {
        (
          config
          ? config.layout?.header?.hidden
          : data.shopLayout?.header?.hidden
        )
        ? null
        : <StoreNavigation hideCart={hideCart} />
      }
      {/* TODO: fix the 87vh to be dynamic */}
      <Layout
        css={{
          flexDirection: 'column',
          minHeight: '87vh',
          // '@desktop-': { overflowX: 'hidden' },
        }}
        language={language?._id || 'en'}
        dir={language?.direction.toLowerCase() || 'ltr'}
      >
        {/* TODO: bind this properly */}
        {posOrderId ? (
          <Cart posOrderId />
        ) : (
          <>
            <Layout css={{ margin: 'auto', flex: 1 }}>
              {children}
              {hideCart ? null : <Cart />}
            </Layout>
            {
              (
                config
                ? config.layout?.footer?.hidden
                : data.shopLayout?.footer?.hidden
              )
              ? <Layout css={{ flex: 1, height: '$ms' }} />
              : <Footer />
            }
            {
              data.consent && data.consent.active
              ? (
                  <Consent
                    title={data.consent.title}
                    body={data.consent.body}
                    confirmLabel={data.consent.labelConfirm}
                    denyLabel={data.consent.labelDeny}
                    onDeny={() => {
                      if (typeof window !== 'undefined') {
                        window.location.replace(
                          process.env.REACT_APP_PACKMAN_SHOP_URL,
                        );
                      }
                    }}
                  />
                )
              : null
            }
          </>
        )}
      </Layout>
      {}
      {data.cookiesConsentActive ? <CookieConsent /> : null}
      {/* TODO: Activate after updating with a button */}
      {hideCart ? null : <FloatingCartButton visible />}
      <Chat />
    </StoreThemeProvider>
  );
}

function BoundStore({
  language,
  isStoreShopPage,
  isCustomDomain,
  storeSlug,
  productOrPageSlug,
  variationSlug,
  posOrderId,
}) {
  const { search } = useLocation();
  const languageObject = useLanguage();

  const { data: store, loading: storeLoading } = useStore();

  const [{
    page: storeHomepage,
    loading: storeHomepageLoading,
  }] = useStorePage(
    { slug: { EQ: '' } },
  );
  const pageSlugFinal = useMemo(
    () => (
      typeof productOrPageSlug === 'string'
      ? productOrPageSlug
      : `${Math.random()}`
    ),
    [productOrPageSlug],
  );
  const [{
    page: storePage,
    loading: storePageLoading,
  }] = useStorePage(
    { slug: { EQ: pageSlugFinal } },
    isStoreShopPage || !productOrPageSlug || storeLoading || !store,
  );

  const storePagesLoading = !!(storeHomepageLoading || storePageLoading);

  const renderCache = useRef();

  if (storeLoading || storePagesLoading || !store) {
    if (renderCache.current?.type === 'PAGE') {
      return renderCache.current?.render || null;
    }
    return null;
  }
  renderCache.current = (() => {
    if (storePage) {
      return {
        type: 'PAGE',
        render: (
          <Store
            data={store}
            language={languageObject}
            config={storePage}
            posOrderId={posOrderId}
          >
            <StorePage
              key={storePage._id}
              page={storePage}
              store={store}
            />
          </Store>
        ),
      };
    }
    if (isStoreShopPage) {
      if (productOrPageSlug) {
        // console.log('shop single');
        return {
          type: 'PRODUCT',
          render: (
            <Store
              data={store}
              language={languageObject}
              posOrderId={posOrderId}
            >
              <StoreProduct key="product" />
            </Store>
          ),
        };
      }
      // console.log('shop archive');
      return {
        type: 'PRODUCTS',
        render: (
          <Store
            data={store}
            language={languageObject}
            posOrderId={posOrderId}
          >
            <StoreProducts key="products" />
          </Store>
        ),
      };
    }
    if (productOrPageSlug) {
      // console.log('redirect: shop single');
      return {
        type: 'REDIRECT',
        render: (
          <StoreRedirect
            language={language}
            isCustomDomain={isCustomDomain}
            storeSlug={storeSlug}
            productOrPageSlug={productOrPageSlug}
            variationSlug={variationSlug}
            search={search}
          />
        ),
      };
    }
    if (storeHomepage) {
      // console.log('store homepage');
      return {
        type: 'PAGE',
        render: (
          <Store
            data={store}
            language={languageObject}
            config={storeHomepage}
            posOrderId={posOrderId}
          >
            <StorePage
              key={storeHomepage._id}
              page={storeHomepage}
              store={store}
            />
          </Store>
        ),
      };
    }
    // console.log('redirect: shop archive');
    return {
      type: 'REDIRECT',
      render: (
        <StoreRedirect
          language={language}
          isCustomDomain={isCustomDomain}
          storeSlug={storeSlug}
          productOrPageSlug={productOrPageSlug}
          variationSlug={variationSlug}
          search={search}
        />
      ),
    };
  })();
  return renderCache.current?.render || null;
}

export default ({
  slug,
  language,
  isStoreShopPage,
  productOrPageSlug,
  variationSlug,
  referral,
  isCustomDomain,
  posOrderId,
}) => (
  <StoreProvider
    slug={slug}
    language={language}
    mode="store"
    isCustomDomain={isCustomDomain}
    referral={referral}
  >
    <AnalyticsProvider>
      <StoreBoundLanguageProvider>
        <CartProvider
          forceInitialClosed={!!productOrPageSlug}
          posOrderId={posOrderId}
        >
          <ProductProvider
            slug={productOrPageSlug}
            variationSlug={variationSlug}
          >
            <BoundStore
              language={language}
              isCustomDomain={isCustomDomain}
              storeSlug={slug}
              productOrPageSlug={productOrPageSlug}
              variationSlug={variationSlug}
              isStoreShopPage={isStoreShopPage}
              posOrderId={posOrderId}
            />
          </ProductProvider>
        </CartProvider>
      </StoreBoundLanguageProvider>
    </AnalyticsProvider>
  </StoreProvider>
);
