import React from 'react';
import { createNamedStyled } from '../../../stitches.config';

import { useDictionary } from '../../../context/Language';

import { Title, Paragraph } from '../../../components/Elements/Text';
import Card from '../../../components/Elements/Card';
import Carousel from '../../../components/Patterns/Carousel';

// TODO: Check layout...

const styled = createNamedStyled('RelatedProducts');

const Wrapper = styled.named('Wrapper')('div', {
  display: 'flex',
  width: '100%',
  flexDirection: 'column',

  '@desktop-': { gap: '$s' },
});

const Content = styled.named('Content')('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 'calc($xs / 3)',

  marginInlineStart: '$s',
  '@desktop+': { marginInlineStart: '$m' },
});

const RelatedProducts = ({ products, ...props }) => {
  const {
    generalRelatedProducts,
    generalRelatedProductsSubtitle,
  } = useDictionary();

  return (
    <Wrapper {...props}>
      <Content>
        <Title size="s">
          {generalRelatedProducts}
        </Title>
        <Paragraph css={{ opacity: 0.6 }}>
          {generalRelatedProductsSubtitle || 'Top Picks for You!'}
        </Paragraph>
      </Content>
      <Carousel>
        {products.map(product => (
          <Card key={product._id} product={product} />
        ))}
      </Carousel>
    </Wrapper>
  );
};

export default RelatedProducts;
